export const INPUT_COLS = ['md', 'inclination', 'azimuth', 'pp', 'pf'];

export const EMPTY_ROW = {
  id: 'id',
  md: 0,
  inclination: 0,
  azimuth: 0,
  x: 0,
  y: 0,
  z: 0,
  deltaX: 0,
  deltaY: 0,
  deltaZ: 0,
  departure: 0,
  dls2D: 0,
  dls3D: 0
};

export const EMPTY_ROW_P_TABLE = {
  id: 'id',
  md: 0,
  inclination: 0,
  azimuth: 0,
  pf: 0,
  pp: 0,
  x: 0,
  y: 0,
  z: 0,
  deltaX: 0,
  deltaY: 0,
  deltaZ: 0,
  departure: 0,
  dls2D: 0,
  dls3D: 0
};

export const P_TABLE_COLUMNS = [
  {
    table_id: 'cd_pore_fracture',
    col_id: 'md',
    col_name: 'MD',
    col_desc: 'Measure Depth',
    col_unit: 'ft',
    ui_editable: true,
    ui_show: true,
    data_type: 'float',
    decimal: 2
  },
  {
    table_id: 'cd_pore_fracture',
    col_id: 'inclination',
    col_name: 'Inclination',
    col_desc: 'Inclination Angle',
    col_unit: 'deg',
    ui_editable: true,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_pore_fracture',
    col_id: 'azimuth',
    col_name: 'Azimuth',
    col_desc: 'Azimuth',
    col_unit: 'deg',
    ui_editable: true,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_pore_fracture',
    col_id: 'pp',
    col_name: 'P Pore',
    col_desc: 'Pore Pressure',
    col_unit: 'psi',
    ui_editable: true,
    ui_show: true,
    data_type: 'float',
    decimal: 2
  },
  {
    table_id: 'cd_pore_fracture',
    col_id: 'pf',
    col_name: 'P Fracture',
    col_desc: 'Fracture Pressure',
    col_unit: 'psi',
    ui_editable: true,
    ui_show: true,
    data_type: 'float',
    decimal: 2
  },
  {
    table_id: 'cd_pore_fracture',
    col_id: 'z',
    col_name: 'z TVD',
    col_desc: 'True Vertical Depth',
    col_unit: 'ft',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 2
  },
  {
    table_id: 'cd_pore_fracture',
    col_id: 'x',
    col_name: 'x +N/-S',
    col_desc: 'x +N/-S',
    col_unit: 'ft',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 2
  },
  {
    table_id: 'cd_pore_fracture',
    col_id: 'y',
    col_name: 'y +E/-W',
    col_desc: 'y +E/-W',
    col_unit: 'ft',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 2
  },
  {
    table_id: 'cd_pore_fracture',
    col_id: 'dx',
    col_name: 'dx',
    col_desc: 'Delta x',
    col_unit: 'ft',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 2
  },
  {
    table_id: 'cd_pore_fracture',
    col_id: 'dy',
    col_name: 'dy',
    col_desc: 'Delta y',
    col_unit: 'ft',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 2
  },
  {
    table_id: 'cd_pore_fracture',
    col_id: 'dz',
    col_name: 'dz',
    col_desc: 'Delta z',
    col_unit: 'ft',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 2
  },
  {
    table_id: 'cd_pore_fracture',
    col_id: 'departure',
    col_name: 'Departure',
    col_desc: 'Horizontal Departure',
    col_unit: 'ft',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_pore_fracture',
    col_id: 'dls_2d',
    col_name: '2D DLS',
    col_desc: '2D Doglet Severity',
    col_unit: 'deg/100ft',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_pore_fracture',
    col_id: 'dls_3d',
    col_name: '3D DLS',
    col_desc: '2D Doglet Severity',
    col_unit: 'deg/100ft',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  }
];

export default P_TABLE_COLUMNS;
