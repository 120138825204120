export const SCHEMA_SENS_HD_ANNULAR_VELOCITY = [
  {
    table_id: 'cd_v_annular_sens',
    col_id: 'scenario',
    col_name: 'Scenario',
    col_desc: 'Scenario',
    col_unit: '-',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 0
  },
  {
    table_id: 'cd_v_annular_sens',
    col_id: 'md',
    col_name: 'MD',
    col_desc: 'Measure Depth',
    col_unit: 'ft',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 2
  },
  {
    table_id: 'cd_v_annular_sens',
    col_id: 'z',
    col_name: 'z TVD',
    col_desc: 'True Vertical Depth',
    col_unit: 'ft',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 2
  },
  {
    table_id: 'cd_v_annular_sens',
    col_id: 'q',
    col_name: 'Flow Rate',
    col_desc: 'Flow Rate',
    col_unit: 'ppg',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 2
  },
  {
    table_id: 'cd_v_annular_sens',
    col_id: 'v_ann',
    col_name: 'Annular Velocity',
    col_desc: 'Annular Velocity',
    col_unit: 'ft/s',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_v_annular_sens',
    col_id: 'ID',
    col_name: 'Annulus ID',
    col_desc: 'Annulus ID',
    col_unit: 'in',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_v_annular_sens',
    col_id: 'OD',
    col_name: 'Annulus OD',
    col_desc: 'Annulus OD',
    col_unit: 'in',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  }
];

export default SCHEMA_SENS_HD_ANNULAR_VELOCITY;
