import { get, isNumber } from 'lodash';

const SUPPORT_UNITS = ['m', 'ft', 'cm', 'in', 'mm'];

const UNIT_FACTORS = [
  { unit: 'm', m: 1, ft: 3.28084, cm: 100, in: 39.3701, mm: 1000 },
  { unit: 'ft', m: 0.3048, ft: 1, cm: 30.48, in: 12, mm: 304.8 },
  { unit: 'cm', m: 0.01, ft: 0.0328084, cm: 1, in: 0.393701, mm: 10 },
  { unit: 'in', m: 0.0254, ft: 0.0833333, cm: 2.54, in: 1, mm: 25.4 },
  { unit: 'mm', m: 0.001, ft: 0.00328084, cm: 0.1, in: 0.0393701, mm: 1 }
];

export function convertLengthUnit(val, fromUnit = 'deg/100ft', toUnit = 'deg/30m') {
  /**
   * Calculate and set dogleg serverity
   *
   * @param {number} val value to be converted. Must be number
   * @param {string} fromUnit
   * @param {string} toUnit
   *
   */

  // Handling invalid input
  if (!SUPPORT_UNITS.includes(fromUnit)) {
    throw new Error(`Invalid unit type of ${fromUnit}. This unit conversion currently is not supported.`);
  }
  if (!SUPPORT_UNITS.includes(toUnit)) {
    throw new Error(`Invalid unit type of ${toUnit}. This unit conversion currently is not supported.`);
  }
  if (typeof val !== 'number') {
    throw new Error(`Invalid unit value of ${val}. This must be a number.`);
  }
  let factor = null;
  UNIT_FACTORS.forEach((row) => {
    const { unit } = row;
    if (unit === fromUnit) {
      factor = get(row, `${toUnit}`, null);
    }
  });
  if (!isNumber(factor)) {
    throw new Error(`Can not find conversion factor from ${fromUnit} to ${toUnit}.`);
  }
  return factor * val;
}

export default convertLengthUnit;
