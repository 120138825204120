import { get, isNumber } from 'lodash';

const SUPPORT_UNITS = ['psi', 'Pa', 'MPa', 'kPa', 'Bar', 'atm'];

const UNIT_FACTORS = [
  {
    unit: 'psi',
    psi: 1,
    Pa: 6894.8,
    MPa: 0.0068948,
    kPa: 6.8948,
    Bar: 0.068948,
    atm: 0.068046
  },
  {
    unit: 'Pa',
    psi: 0.0001450368394,
    Pa: 1,
    MPa: 0.000001,
    kPa: 0.001,
    Bar: 0.00001,
    atm: 0.000009869232667
  },
  {
    unit: 'MPa',
    psi: 145.0368394,
    Pa: 1000000,
    MPa: 1,
    kPa: 1000,
    Bar: 10,
    atm: 9.869232667
  },
  {
    unit: 'kPa',
    psi: 0.1450368394,
    Pa: 1000,
    MPa: 0.001,
    kPa: 1,
    Bar: 0.01,
    atm: 0.0098692327
  },
  {
    unit: 'Bar',
    psi: 14.50368394,
    Pa: 100000,
    MPa: 0.1,
    kPa: 100,
    Bar: 1,
    atm: 0.9869232667
  },
  {
    unit: 'atm',
    psi: 14.69594098,
    Pa: 101325,
    MPa: 0.101325,
    kPa: 101.325,
    Bar: 1.01325,
    atm: 1
  }
];

export function convertPressureUnit(val, fromUnit = 'psi', toUnit = 'MPa') {
  /**
   * Calculate and set dogleg serverity
   *
   * @param {number} val value to be converted. Must be number
   * @param {string} fromUnit
   * @param {string} toUnit
   *
   */

  // Handling invalid input
  if (!SUPPORT_UNITS.includes(fromUnit)) {
    throw new Error(`Invalid unit type of ${fromUnit}. This unit conversion currently is not supported.`);
  }
  if (!SUPPORT_UNITS.includes(toUnit)) {
    throw new Error(`Invalid unit type of ${toUnit}. This unit conversion currently is not supported.`);
  }
  if (typeof val !== 'number') {
    throw new Error(`Invalid unit value of ${val}. This must be a number.`);
  }
  let factor = null;
  UNIT_FACTORS.forEach((row) => {
    const { unit } = row;
    if (unit === fromUnit) {
      factor = get(row, `${toUnit}`, null);
    }
  });
  if (!isNumber(factor)) {
    throw new Error(`Can not find conversion factor from ${fromUnit} to ${toUnit}.`);
  }
  return factor * val;
}

export default convertPressureUnit;
