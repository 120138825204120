export const SCHEMA_SENS_DT_SUMMARY = [
  {
    table_id: 'cd_dnt_summary_sens',
    col_id: 'scenario',
    col_name: 'Scenario',
    col_desc: 'Scenario',
    col_unit: '-',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 0
  },
  {
    table_id: 'cd_dnt_summary_sens',
    col_id: 'WOB',
    col_name: 'WOB',
    col_desc: 'Weight on Bit',
    col_unit: 'lb',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 0
  },
  {
    table_id: 'cd_dnt_summary_sens',
    col_id: 'max_WOB',
    col_name: 'Maximum WOB',
    col_desc: 'Maximum Weight on Bit',
    col_unit: 'lb',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 0
  },
  {
    table_id: 'cd_dnt_summary_sens',
    col_id: 'neutral_length',
    col_name: 'Neutral Length',
    col_desc: 'Neutral Length',
    col_unit: 'ft',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 0
  },
  {
    table_id: 'cd_dnt_summary_sens',
    col_id: 'drillcollar_length',
    col_name: 'Drillcollar Length',
    col_desc: 'Drillcollar Length',
    col_unit: 'ft',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 0
  },
  {
    table_id: 'cd_dnt_summary_sens',
    col_id: 'buckling_risk',
    col_name: 'Buckling Risk',
    col_desc: 'Buckling Risk',
    col_unit: '-',
    ui_editable: false,
    ui_show: true,
    data_type: 'bool',
    decimal: 0
  }
];

export default SCHEMA_SENS_DT_SUMMARY;
