export const SCHEMA_SENS_HD_HYDRAULIC = [
  {
    table_id: 'cd_hydraulic_sens',
    col_id: 'scenario',
    col_name: 'Scenario',
    col_desc: 'Scenario',
    col_unit: '-',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 0
  },
  {
    table_id: 'cd_hydraulic_sens',
    col_id: 'md',
    col_name: 'MD',
    col_desc: 'Measure Depth',
    col_unit: 'ft',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 2
  },
  {
    table_id: 'cd_hydraulic_sens',
    col_id: 'z',
    col_name: 'z TVD',
    col_desc: 'True Vertical Depth',
    col_unit: 'ft',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 2
  },
  {
    table_id: 'cd_hydraulic_sens',
    col_id: 'pp',
    col_name: 'P Pore',
    col_desc: 'Pore Pressure',
    col_unit: 'psi',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 2
  },
  {
    table_id: 'cd_hydraulic_sens',
    col_id: 'pf',
    col_name: 'P Fracture',
    col_desc: 'Fracture Pressure',
    col_unit: 'psi',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 2
  },
  {
    table_id: 'cd_hydraulic_sens',
    col_id: 'ED_pp',
    col_name: 'ED Pore',
    col_desc: 'Pore Equivalent Density',
    col_unit: 'ppg',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_hydraulic_sens',
    col_id: 'ED_pf',
    col_name: 'ED Fracture',
    col_desc: 'Fracture  Equivalent Density',
    col_unit: 'ppg',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_hydraulic_sens',
    col_id: 'q',
    col_name: 'Flow Rate',
    col_desc: 'Circulate Rate',
    col_unit: 'gpm',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_hydraulic_sens',
    col_id: 'P_static',
    col_name: 'P Static',
    col_desc: 'Static Pressure',
    col_unit: 'psi',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_hydraulic_sens',
    col_id: 'ED_static',
    col_name: 'Static ED',
    col_desc: 'Static Equivalent Density',
    col_unit: 'ppg',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_hydraulic_sens',
    col_id: 'Pfric_tot',
    col_name: 'dP Friction',
    col_desc: 'Total dP Friction in Pipe and Annulus',
    col_unit: 'psi',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_hydraulic_sens',
    col_id: 'P_drillbit',
    col_name: 'dP DrillBit',
    col_desc: 'dP DrillBit',
    col_unit: 'psi',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_hydraulic_sens',
    col_id: 'P_pump',
    col_name: 'P Pump',
    col_desc: 'Required Mud Pump Pressure',
    col_unit: 'psi',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_hydraulic_sens',
    col_id: 'HP_req',
    col_name: 'HP Pump',
    col_desc: 'Required Mud Pump Power',
    col_unit: 'HP',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_hydraulic_sens',
    col_id: 'BHP',
    col_name: 'BHP',
    col_desc: 'Bottom hole Pressure',
    col_unit: 'psi',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_hydraulic_sens',
    col_id: 'ECD',
    col_name: 'ECD',
    col_desc: 'Equivalent Circulation Density',
    col_unit: 'ppg',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_hydraulic_sens',
    col_id: 'Ptrip_total',
    col_name: 'dP Trip',
    col_desc: 'Total dP Tripping',
    col_unit: 'psi',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_hydraulic_sens',
    col_id: 'P_surge',
    col_name: 'P Surge',
    col_desc: 'Surge Pressure',
    col_unit: 'psi',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_hydraulic_sens',
    col_id: 'P_swab',
    col_name: 'P Swab',
    col_desc: 'Swab Pressure',
    col_unit: 'psi',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_hydraulic_sens',
    col_id: 'ED_surge',
    col_name: 'Surge ED',
    col_desc: 'Surge ED',
    col_unit: 'psi',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_hydraulic_sens',
    col_id: 'ED_swab',
    col_name: 'Swab ED',
    col_desc: 'Swab ED',
    col_unit: 'psi',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  }
];

export default SCHEMA_SENS_HD_HYDRAULIC;
