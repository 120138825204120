import { get, isEmpty } from 'lodash';
import { convertLengthUnit } from 'utils/unit_conversion/lengthUnit';
import { convertDoglegUnit } from 'utils/unit_conversion/doglegUnit';
import { convertPressureUnit } from 'utils/unit_conversion/pressureUnit';

const DECNO = 2;

export const EMPTY_ROW = {
  id: 'id',
  md: 0,
  inclination: 0,
  azimuth: 0,
  pf: 0,
  pp: 0,
  z: 0,
  x: 0,
  y: 0,
  dx: 0,
  dy: 0,
  dz: 0,
  departure: 0,
  dls_2d: 0,
  dls_3d: 0
};

const UOM_DEFAULT = {
  md_uom: 'ft',
  pp_uom: 'psi',
  pf_uom: 'psi',
  inclination_uom: 'deg',
  azimuth_uom: 'deg',
  z_uom: 'ft',
  x_uom: 'ft',
  y_uom: 'ft',
  dx_uom: 'ft',
  dy_uom: 'ft',
  dz_uom: 'ft',
  departure_uom: 'ft',
  dls_2d_uom: 'deg/100ft',
  dls_3d_uom: 'deg/100ft'
};

// UTILS FUNCTION
// ==========================

function dSin(deg) {
  return Math.sin((deg * Math.PI) / 180);
}

function dCos(deg) {
  return Math.cos((deg * Math.PI) / 180);
}

function limitDec(num) {
  return parseFloat(num.toFixed(DECNO));
}

function getRowPair(rowsById, rowIds, rowIndex) {
  /* return a pair of rows: the current index and the previous one */
  let prevRow = null;
  if (rowIndex === 0) {
    prevRow = EMPTY_ROW;
  } else {
    prevRow = rowsById[rowIds[rowIndex - 1]];
  }
  const row = rowsById[rowIds[rowIndex]];
  return [prevRow, row];
}

function setDogLeg(prevRow, currRow) {
  /**
   * Calculate and set dogleg serverity
   *
   * @param {object} prevRow previous row data
   * @param {object} currRow current row data
   *
   */
  const FACTOR = 100;
  const I1 = prevRow.inclination;
  const I2 = currRow.inclination;
  const deltaI = currRow.inclination - prevRow.inclination;
  const deltaA = currRow.azimuth - prevRow.azimuth;
  const deltaMD = currRow.md - prevRow.md;
  const temp1 = dSin(deltaI / 2) ** 2;
  const temp2 = dSin(deltaA / 2) ** 2;
  const temp3 = temp1 + dSin(I1) * dSin(I2) * temp2;

  if (deltaMD === 0) {
    currRow.dls_2d = 0;
    currRow.dls_3d = 0;
  } else {
    currRow.dls_2d = Math.abs(limitDec((deltaI * FACTOR) / deltaMD));
    const dls3D = ((180 / Math.PI) * 2 * Math.asin(Math.sqrt(temp3)) * FACTOR) / deltaMD;
    currRow.dls_3d = limitDec(dls3D);
  }
}

function setXyzHD(prevRow, currRow, delta) {
  /**
   * Calculate and set x, y, z coordinates and horizontal departure
   * to current row base on the previous row and delta
   *
   * @param {object} prevRow previous row
   * @param {object} currRow current row
   * @param {object} delta { dx, dy, dz }
   *
   */
  currRow.x = limitDec(prevRow.x + delta.dx);
  currRow.y = limitDec(prevRow.y + delta.dy);
  currRow.z = limitDec(prevRow.z + delta.dz);
  currRow.departure = limitDec(Math.sqrt(currRow.x ** 2 + currRow.y ** 2));
}

// ======================| METHOD |====================== //

// AVERAGE ANGLE
function getAverageAngleDelta(deltaMD, I1, I2, A1, A2) {
  const avgI = (I1 + I2) / 2;
  const avgA = (A1 + A2) / 2;
  const dx = deltaMD * dSin(avgI) * dCos(avgA);
  const dy = deltaMD * dSin(avgI) * dSin(avgA);
  const dz = deltaMD * dCos(avgI);
  return {
    dx: limitDec(dx),
    dy: limitDec(dy),
    dz: limitDec(dz)
  };
}

// BALANCE TANGENIAL
function getBalanceTangentialDelta(deltaMD, I1, I2, A1, A2) {
  const dx = (deltaMD / 2) * (dSin(I1) * dCos(A1) + dSin(I2) * dCos(A2));
  const dy = (deltaMD / 2) * (dSin(I1) * dSin(A1) + dSin(I2) * dSin(A2));
  const dz = (deltaMD / 2) * (dCos(I1) + dCos(I2));
  return {
    dx: limitDec(dx),
    dy: limitDec(dy),
    dz: limitDec(dz)
  };
}

// MINIMUM CURVATURE
function getMinimumCurvatureDelta(deltaMD, I1, I2, A1, A2, dls3D) {
  let beta = dls3D;
  if (beta === 0) beta = 1;
  beta = (beta * Math.PI) / 180;
  const rf = (deltaMD / beta) * Math.tan(beta / 2);
  const dx = rf * (dSin(I1) * dCos(A1) + dSin(I2) * dCos(A2));
  const dy = rf * (dSin(I1) * dSin(A1) + dSin(I2) * dSin(A2));
  const dz = rf * (dCos(I1) + dCos(I2));
  return {
    dx: limitDec(dx),
    dy: limitDec(dy),
    dz: limitDec(dz)
  };
}

function getDeltaFromMethod(prevRow, currRow, currentMethod) {
  const deltaMD = parseFloat(currRow.md) - parseFloat(prevRow.md);
  const I1 = parseFloat(prevRow.inclination);
  const I2 = parseFloat(currRow.inclination);
  const A1 = parseFloat(prevRow.azimuth);
  const A2 = parseFloat(currRow.azimuth);
  const dls3D = parseFloat(currRow.dls_3d);

  switch (currentMethod) {
    case 'AA':
      return getAverageAngleDelta(deltaMD, I1, I2, A1, A2);
    case 'BT':
      return getBalanceTangentialDelta(deltaMD, I1, I2, A1, A2);
    case 'MC':
      return getMinimumCurvatureDelta(deltaMD, I1, I2, A1, A2, dls3D);
    default:
      throw Error('Invalid Method');
  }
}

export function calculateDirectionalSurveyGivenUnit({ rowData, currentMethod, pUom, sUom }) {
  /**
   * Calculate the whole well survey table for given input rows, calculation method and setting unit
   *
   * @param {array} rowData Row Data
   * @param {string} currentMethod calculation method 'MC' || 'AA' || 'BT'
   * @param {object} pUom
   * @param {object} sUom
   *
   */

  // STEP 1: NORMALIZE TO DEFAULT UNIT
  const normalizedUnitRowData = [];
  const rowsById = {};
  const rawRowsById = {};
  const rowIds = [];
  rowData.forEach((row) => {
    const { id } = row;
    // Only need to convert md as input
    const newRow = {
      id,
      md: limitDec(convertLengthUnit(row.md, pUom.md_uom, UOM_DEFAULT.md_uom)),
      pp: row.pp,
      pf: row.pf,
      inclination: row.inclination,
      azimuth: row.azimuth,
      z: 0,
      x: 0,
      y: 0,
      dx: 0,
      dy: 0,
      dz: 0,
      departure: 0,
      dls_2d: 0,
      dls_3d: 0
    };
    rowsById[id] = newRow;
    rawRowsById[id] = row;
    rowIds.push(id);
    normalizedUnitRowData.push(newRow);
  });

  // STEP 2: ACTUAL CALCULATION WITH DEFAULT UNIT
  const calculatedRowData = [];
  rowIds.forEach((id, rowIndex) => {
    const [prevRow, row] = getRowPair(rowsById, rowIds, rowIndex);
    // Copy the row and start setting new calculated data
    const currRow = { ...row };
    setDogLeg(prevRow, currRow);
    const delta = getDeltaFromMethod(prevRow, currRow, currentMethod);
    setXyzHD(prevRow, currRow, delta);
    // Update row
    const calculatedRow = { ...currRow, ...delta };

    // Assign for next calculate step
    rowsById[id] = calculatedRow;
    // Update newRowData
    calculatedRowData.push(calculatedRow);
  });
  // STEP 3: CONVERT TO THE UNIT GIVEN BY USERS
  const defaultUnitRowData = [];
  const pTblRowData = [];

  calculatedRowData.forEach((row) => {
    const { id, md, pp, pf, inclination, azimuth, x, y, z, dx, dy, dz, departure } = row;
    const dls2D = get(row, 'dls_2d', 0);
    const dls3D = get(row, 'dls_3d', 0);
    pTblRowData.push({
      id,
      md: limitDec(rawRowsById[id].md),
      inclination: limitDec(rawRowsById[id].inclination),
      azimuth: limitDec(rawRowsById[id].azimuth),
      pp: limitDec(rawRowsById[id].pp),
      pf: limitDec(rawRowsById[id].pf),
      z: limitDec(convertLengthUnit(row.z, UOM_DEFAULT.z_uom, pUom.z_uom))
    });
    defaultUnitRowData.push([md, inclination, azimuth, pp, pf, z, x, y, dx, dy, dz, departure, dls2D, dls3D]);
  });

  const finalObj = {
    survey_method: currentMethod,
    p_tbl_uom: pUom,
    p_tbl: pTblRowData,
    survey_tbl_uom: sUom,
    survey_tbl_data: defaultUnitRowData
  };
  return finalObj;
}

export function getDirectionalSurveyDataForSelectedUnit(pressureObj) {
  const uom = get(pressureObj, 'survey_tbl_uom', {});
  const defaultData = get(pressureObj, 'survey_tbl_data', []);
  const colSchema = ['md', 'inclination', 'azimuth', 'pp', 'pf', 'z', 'x', 'y', 'dx', 'dy', 'dz', 'departure', 'dls_2d', 'dls_3d'];
  const selectedUnitRowData = [];
  defaultData.forEach((row) => {
    const newRow = [];
    colSchema.forEach((colId, idx) => {
      let newVal = null;
      if (['md', 'z', 'x', 'y', 'dx', 'dy', 'dz', 'departure'].includes(colId)) {
        newVal = convertLengthUnit(row[idx], UOM_DEFAULT[`${colId}_uom`], uom[`${colId}_uom`]);
      }
      if (colId === 'inclination' || colId === 'azimuth') {
        newVal = row[idx];
      }
      if (['pp', 'pf'].includes(colId)) {
        newVal = convertPressureUnit(row[idx], UOM_DEFAULT[`${colId}_uom`], uom[`${colId}_uom`]);
      }
      if (['dls_2d', 'dls_3d'].includes(colId)) {
        newVal = convertDoglegUnit(row[idx], UOM_DEFAULT[`${colId}_uom`], uom[`${colId}_uom`]);
      }
      newRow.push(limitDec(newVal));
    });
    selectedUnitRowData.push(newRow);
  });
  return selectedUnitRowData;
}
