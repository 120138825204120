import api from '../../api';

const BASE_URL = '/drilling-design/v2/project';

// PROJECT CRUD
// =================================================

export const fetchProjectList = async (owner) => {
  const response = await api.get(`${BASE_URL}?owner=${owner}`);
  return response.data;
};

export const readProject = async (projectId) => {
  const response = await api.get(`${BASE_URL}/${projectId}`);
  return response.data;
};

export const updateProject = async (projectId, project) => {
  const response = await api.put(`${BASE_URL}/${projectId}`, project);
  return response.data;
};

export const addProject = async (project) => {
  const response = await api.post(`${BASE_URL}`, project);
  return response.data;
};

export const duplicateProject = async (project) => {
  const response = await api.post(`${BASE_URL}`, project);
  return response.data;
};

export const removeProject = async (projectId) => {
  const response = await api.delete(`${BASE_URL}/${projectId}`);
  return response.data;
};

export const permanentDeleteProjects = async () => {
  const response = await api.delete(`${BASE_URL}`);
  return response.data;
};

export const createDemoProject = async (payload) => {
  const response = await api.post(`${BASE_URL}/demo`, payload);
  return response.data;
};

// PROJECT CONTROLLER
// =================================================

export const updateCasingDesign = async (projectId) => {
  const response = await api.put(`${BASE_URL}/${projectId}/action`);
  return response.data;
};

export const deleteCasingDesign = async (projectId) => {
  const response = await api.delete(`${BASE_URL}/${projectId}/action`);
  return response.data;
};

// PROJECT INPUT AND OUTPUT
// =================================================

export const readProjectInputGeneral = async (projectId) => {
  const response = await api.get(`${BASE_URL}/${projectId}/input-general`);
  return response.data;
};

export const updateProjectInputGeneral = async (projectId, obj) => {
  const response = await api.put(`${BASE_URL}/${projectId}/input-general`, obj);
  return response.data;
};

export const readProjectInputPressure = async (projectId) => {
  const response = await api.get(`${BASE_URL}/${projectId}/input-pressure`);
  return response.data;
};

export const updateProjectInputPressure = async (projectId, obj) => {
  const response = await api.put(`${BASE_URL}/${projectId}/input-pressure`, obj);
  return response.data;
};

export const readProjectInputDrillingProgram = async (projectId) => {
  const response = await api.get(`${BASE_URL}/${projectId}/input-drilling-program`);
  return response.data;
};

export const updateProjectInputDrillingProgram = async (projectId, obj) => {
  const response = await api.put(`${BASE_URL}/${projectId}/input-drilling-program`, obj);
  return response.data;
};

export const readOutputCasingDesign = async (projectId) => {
  const response = await api.get(`${BASE_URL}/${projectId}/output-casing-design`);
  return response.data;
};

export const updateOutputCasingDesign = async (projectId, obj) => {
  const response = await api.put(`${BASE_URL}/${projectId}/output-casing-design`, obj);
  return response.data;
};

export const readOutputDragTorque = async (projectId) => {
  const response = await api.get(`${BASE_URL}/${projectId}/output-drag-torque`);
  return response.data;
};

export const updateOutputDragTorque = async (projectId, obj) => {
  const response = await api.put(`${BASE_URL}/${projectId}/output-drag-torque`, obj);
  return response.data;
};

export const readOutputHydraulic = async (projectId) => {
  const response = await api.get(`${BASE_URL}/${projectId}/output-hydraulic`);
  return response.data;
};

export const updateOutputHydraulic = async (projectId, obj) => {
  const response = await api.put(`${BASE_URL}/${projectId}/output-hydraulic`, obj);
  return response.data;
};

// PROJECT CRUD
// =================================================

export const fetchSensitivityList = async (projectId) => {
  const response = await api.get(`${BASE_URL}/${projectId}/sensitivity`);
  return response.data;
};

export const readSensitivity = async (sensitivityId) => {
  const response = await api.get(`${BASE_URL}/sensitivity/${sensitivityId}`);
  return response.data;
};

export const updateSensitivity = async (sensitivityId, sensitivity) => {
  const response = await api.put(`${BASE_URL}/sensitivity/${sensitivityId}`, sensitivity);
  return response.data;
};

export const addSensitivity = async (sensitivity) => {
  const response = await api.post(`${BASE_URL}/sensitivity`, sensitivity);
  return response.data;
};

export const duplicateSensitivity = async (sensitivity) => {
  const response = await api.post(`${BASE_URL}/sensitivity`, sensitivity);
  return response.data;
};

export const removeSensitivity = async (sensitivityId) => {
  const response = await api.delete(`${BASE_URL}/sensitivity/${sensitivityId}`);
  return response.data;
};

export const runSensitivity = async (sensitivityId) => {
  const response = await api.post(`${BASE_URL}/sensitivity/${sensitivityId}/run`);
  return response.data;
};

export const readStatusSensitivity = async (sensitivityId) => {
  const response = await api.get(`${BASE_URL}/sensitivity/${sensitivityId}/status`);
  return response.data;
};

export const readCdSensitivity = async (sensitivityId) => {
  const response = await api.get(`${BASE_URL}/sensitivity/casing-design/${sensitivityId}`);
  return response.data;
};

export const updateCdSensitivity = async (sensitivityId, sensitivity) => {
  const response = await api.put(`${BASE_URL}/sensitivity/casing-design/${sensitivityId}`, sensitivity);
  return response.data;
};

export const readHdSensitivity = async (sensitivityId) => {
  const response = await api.get(`${BASE_URL}/sensitivity/hydraulic-design/${sensitivityId}`);
  return response.data;
};

export const updateHdSensitivity = async (sensitivityId, sensitivity) => {
  const response = await api.put(`${BASE_URL}/sensitivity/hydraulic-design/${sensitivityId}`, sensitivity);
  return response.data;
};

export const readDtSensitivity = async (sensitivityId) => {
  const response = await api.get(`${BASE_URL}/sensitivity/drag-torque/${sensitivityId}`);
  return response.data;
};

export const updateDtSensitivity = async (sensitivityId, sensitivity) => {
  const response = await api.put(`${BASE_URL}/sensitivity/drag-torque/${sensitivityId}`, sensitivity);
  return response.data;
};

// ACTIVITY CRUD
// =================================================

export const readManyActivity = async () => {
  const response = await api.get(`/completion-design/activity`);
  return response.data;
};
