import { get, isNumber } from 'lodash';

const SUPPORT_UNITS = ['deg/100ft', 'deg/30m', 'deg/ft', 'deg/m'];

const UNIT_FACTORS = [
  { unit: 'deg/100ft', 'deg/100ft': 1, 'deg/30m': 0.984, 'deg/ft': 0.01, 'deg/m': 0.0328 },
  { unit: 'deg/30m', 'deg/100ft': 1.016, 'deg/30m': 1, 'deg/ft': 30.48, 'deg/m': 0.0333 },
  { unit: 'deg/ft', 'deg/100ft': 100, 'deg/30m': 98.425, 'deg/ft': 0.01016, 'deg/m': 3.28 },
  { unit: 'deg/m', 'deg/100ft': 30.4878, 'deg/30m': 30, 'deg/ft': 0.3048, 'deg/m': 1 }
];

export function convertDoglegUnit(val, fromUnit = 'deg/100ft', toUnit = 'deg/30m') {
  /**
   * Calculate and set dogleg serverity
   *
   * @param {number} val value to be converted. Must be number
   * @param {string} fromUnit
   * @param {string} toUnit
   *
   */

  // Handling invalid input
  if (!SUPPORT_UNITS.includes(fromUnit)) {
    throw new Error(`Invalid unit type of ${fromUnit}. This unit conversion currently is not supported.`);
  }
  if (!SUPPORT_UNITS.includes(toUnit)) {
    throw new Error(`Invalid unit type of ${toUnit}. This unit conversion currently is not supported.`);
  }
  if (typeof val !== 'number') {
    throw new Error(`Invalid unit value of ${val}. This must be a number.`);
  }
  let factor = null;
  UNIT_FACTORS.forEach((row) => {
    const { unit } = row;
    if (unit === fromUnit) {
      factor = get(row, `${toUnit}`, null);
    }
  });
  if (!isNumber(factor)) {
    throw new Error(`Can not find conversion factor from ${fromUnit} to ${toUnit}.`);
  }
  return factor * val;
}

export default convertDoglegUnit;
